export const toggleBoolean = (prev: boolean) => !prev;

const isValidArrayIndex = (arr: any[], idx: number) => {
  return !(idx < 0 || idx >= arr.length);
};

export function addValueAtIndex(arr: any[], idx: number, value: any) {
  if (!isValidArrayIndex(arr, idx) && idx !== arr.length) {
    throw new Error(`Cannot add value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), value, ...arr.slice(idx)];
}

export function replaceValueAtIndex(arr: any[], idx: number, newValue: any) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot replace value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), newValue, ...arr.slice(idx + 1)];
}

export function updateValueAtIndex(arr: any[], idx: number, updater: any) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot update value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), updater(arr[idx]), ...arr.slice(idx + 1)];
}

export function removeValueAtIndex(arr: any[], idx: number) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot remove value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
}

export function decimalToPercentage(decimal: any) {
  if (decimal === 0) {
    return 0;
  } else {
    return (decimal * 100);
  }
}

export function addThousandsSeparator(input: string | number | undefined) {
  if(!input) return "";
  // Convert the input to a string
  let numberStr = String(input);

  // Add commas as thousands separators
  numberStr = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return numberStr;
}

export function hideWalletAddress(walletAddress: string) {
  if(!walletAddress) return "";
  
  return (
    walletAddress.substring(0, 5) +
    "..." +
    walletAddress.substring(walletAddress?.length - 4, walletAddress?.length)
  );
}

export function truncateUrl(url: string) {
  const maxLength = 30; // maximum number of characters to display
  return url.length > maxLength ? url.substring(0, maxLength) + '...' : url;
};

export function formatNumberInLiteral(num: string | number | undefined) {
  if(!num) return "";
  if(typeof num === "string") num = Number(num);
  // If the number is less than 100, return it as is
  if (num < 100) return num.toString();

  let units = ["", "K", "M", "B", "T"];
  let unitIndex = Math.floor((num.toString().length - 1) / 3) * 3;
  let unitValue = Math.pow(10, unitIndex);
  let numStr = (num / unitValue).toFixed(1);
  let unit = units[Math.floor(unitIndex / 3)];
  
  // Remove unnecessary decimals
  if (numStr.indexOf('.0') === numStr.length - 2) {
    numStr = numStr.substring(0, numStr.length - 2);
  }

  return numStr + unit;
}

export function getFormattedTimeDifference(currentTimestamp: number, endTimestamp: number) {
  // Calculate the difference in milliseconds
  const difference = endTimestamp - currentTimestamp;

  // Calculate days, hours, minutes
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((difference / (1000 * 60)) % 60);

  let result = "";
  if (days > 0) result += days + "d ";
  if (hours > 0) result += hours + "h ";
  if (minutes > 0) result += minutes + "m";

  return result.trim();
}

export function getUtcEndTimestamps() {
  const now = new Date();

  // End of today in UTC
  const endOfDay = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

  // End of current week in UTC (assuming week ends on Saturday)
  const endOfWeek = new Date(Date.UTC(
    now.getUTCFullYear(), 
    now.getUTCMonth(), 
    now.getUTCDate() + (6 - now.getUTCDay()), // Move to the next Saturday
    23, 59, 59, 999
  ));

  // End of current month in UTC
  const endOfMonth = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 0, 23, 59, 59, 999));

  return {
    endOfDayTimestamp: endOfDay.getTime(),
    endOfWeekTimestamp: endOfWeek.getTime(),
    endOfMonthTimestamp: endOfMonth.getTime()
  };
}

export function removeDuplicates(arr?: any[], caseInsensitive: boolean = false) {
  if (!arr) return [];

  if (caseInsensitive) {
    const lowerCaseSet = new Set();
    const unique = [];
    for (const item of arr) {
      const value = typeof item === 'string' ? item.toLowerCase() : item;
      if (!lowerCaseSet.has(value)) {
        lowerCaseSet.add(value);
        unique.push(item);
      }
    }
    return unique;
  } else {
    const unique = arr.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });
    return unique;
  }
}

export function reload() {
  if(typeof window !== 'undefined') {
    window.location.reload();
  }
}

export function getWindow() {
  if(typeof window !== 'undefined') {
    return window;
  }
}

export function isValidEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const getMonthAndWeekOfMonth = (dateInput: string | Date) => {
  if(!dateInput) return null;
  // Parse the input into a Date object if it's a string
  const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;

  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new Error('Invalid date input');
  }

  // Get the month name
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const monthName = monthNames[date.getMonth()];

  // Get the first day of the current month
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const firstDayOfWeek = firstDayOfMonth.getDay(); // Day of the week for the first day (0 is Sunday)

  // Calculate the week number within the month
  const dayOfMonth = date.getDate();
  const weekNumberOfMonth = Math.ceil((dayOfMonth + firstDayOfWeek) / 7);

  return {
    month: monthName,
    weekNumberOfMonth: weekNumberOfMonth
  };
}

// Formats to "1 Dec 2024"
export const formatDate = (dateInput: string | Date) => {
  if(!dateInput) return null;
  const dateObj = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;

  if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) {
    console.log('Invalid date input');
    return null;
  }
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return dateObj.toLocaleDateString('en-GB', options as any);
}

export async function retryUntilSuccess(
  callback: () => Promise<any>,
  checkField = "",
  maxRetries = 10,
  delay = 1000
) {
  let retries = 0;
  while (retries < maxRetries) {
    const result = await callback();
    if (result) {
      if(checkField) {
        if(result[checkField]) return result
      }
      else {
        return result;
      }
    }
    retries++;
    console.log(`Retrying... (${retries}/${maxRetries})`);
    await new Promise((resolve) => setTimeout(resolve, delay));
  }
  console.log("Max retries reached. Failed to get a valid result.");
  return null;
}

export function getDateString(dateStr: string) {
  try {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }
  catch(error) {
    console.log("Error while converting date to string", error)
    return null;
  }
}

export function openPopup(link: string, name: string, width = 600, height = 600) {
    const left = (window.screen.width - width) / 2; // Center the popup horizontally
    const top = (window.screen.height - height) / 2; // Center the popup vertically
  
    window.open(
      link, // URL to open
      name, // Name of the popup window
      `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes,status=yes`
    );
}