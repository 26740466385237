import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import {
  armor,
  chaseTowerIcon,
  closeIcon,
  crossIconWhite,
  turkishKayiAccountImg,
  kayiArcher,
  kayiArcher2,
  mobileModalHeader,
  registerModalBg,
} from "../../images/general";
import CustomButtton from "../Button/CustomButtton";
import { useAuth } from "../../context/AuthContext";
import * as Realm from "realm-web";
//@ts-ignore
import { useRealmApp } from "../../utils/RealmApp";
import ConfirmEmailDialog from "./ConfirmOrResetEmail";
import { StringParam, useQueryParam } from "use-query-params";

const ResetPassword = ({
  email,
  setEmail,
  isLoading,
  onResetPassword,
  onBack,
  errors,
}: {
  email: string;
  setEmail: any;
  isLoading: boolean;
  onResetPassword: (email: string) => void;
  onBack: () => void;
  errors: Record<string, string> | undefined;
}) => {
  return (
    <>
      <div className="sm:px-[3.7rem] px-4 sm:pt-11 pt-3">
        <h3 className="text-2xl sm:text-[40px] leading-10 text-white font-bold font-cinzel sm:mb-8 mb-3">
          Reset Password
        </h3>
        <form>
          <div className="mb-3">
            <label
              htmlFor="email"
              className="block text-white text-sm leading-6 font-semibold mb-3"
            >
              Email address *
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              disabled={isLoading}
              onChange={(e) => setEmail(e.target.value)}
              className=" border border-[#667582] rounded-md px-4 py-2 w-full bg-transparent text-white outline-none focus:outline-none h-12"
              placeholder="Enter your email address"
              required
            />
          </div>
        </form>
      </div>
      <div className="sm:px-[3.7rem] px-4 py-4 mt-36">
        <div className="flex flex-row justify-between items-baseline sm:mt-16">
          <div>
            <CustomButtton
              text="Send Reset Email"
              icon2={chaseTowerIcon}
              onClick={() => onResetPassword(email)}
              disabled={!email || isLoading}
              isLoading={isLoading}
            />
            {!isLoading && (
              <p
                className="text-white text-sm leading-6 mt-7 mb-5 underline text-center sm:text-left cursor-pointer"
                onClick={onBack}
              >
                Go back
              </p>
            )}
          </div>
          {errors?._ && (
            <div className="font-garamond text-red">{errors._}</div>
          )}
        </div>
      </div>
    </>
  );
};

const ChangePassword = ({
  onChangePassword,
  isLoading,
  errors,
}: {
  onChangePassword: (password: string) => void;
  isLoading: boolean;
  errors: Record<string, string> | undefined;
}) => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const isPasswordNotMatch = password !== confirmPassword;
  return (
    <>
      <div className="sm:px-[3.7rem] px-4 sm:pt-11 pt-3">
        <h3 className="text-2xl sm:text-[40px] leading-10 text-white font-bold font-cinzel sm:mb-8 mb-3">
          Reset Password
        </h3>
        <form>
          <div className="mb-3">
            <label
              htmlFor="email"
              className="block text-white text-sm leading-6 font-semibold mb-3"
            >
              Password *
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              disabled={isLoading}
              onChange={(e) => setPassword(e.target.value)}
              className="border border-[#667582] rounded-md px-4 py-2 w-full bg-transparent text-white outline-none focus:outline-none h-12"
              placeholder="Password"
              required
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="email"
              className="block text-white text-sm leading-6 font-semibold mb-3"
            >
              Confirm Password *
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={confirmPassword}
              disabled={isLoading}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className=" border border-[#667582] rounded-md px-4 py-2 w-full bg-transparent text-white outline-none focus:outline-none h-12"
              placeholder="Confirm Password"
              required
            />
          </div>
          {confirmPassword && isPasswordNotMatch
                && <div className="font-garamond text-red">Password and Confirm Password not matched</div>}
        </form>
      </div>
      <div className="sm:px-[3.7rem] px-4 py-4">
        <div className="flex flex-row justify-between items-baseline sm:mt-16">
          <div>
            <CustomButtton
              text="Change Password"
              icon2={chaseTowerIcon}
              onClick={() => onChangePassword(password)}
              disabled={
                !password || !confirmPassword || isPasswordNotMatch || isLoading
              }
              isLoading={isLoading}
            />
          </div>
          {errors?._ && (
            <div className="font-garamond text-red">
              {errors?._}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const ResetModal = ({
  isVisible,
  onClose,
}: {
  isVisible: boolean;
  onClose: () => void;
}) => {
  const realmApp: Realm.App = useRealmApp();
  const { currentUser } = realmApp;

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = React.useState<Record<string, string>>();

  const [token, setToken] = useQueryParam("token", StringParam);
  const [tokenId, setTokenId] = useQueryParam("tokenId", StringParam);

  const canChangePassword = token && tokenId;

  const {
    actions: { setIsLoginModalOpen },
  } = useAuth();

  const onBack = () => {
    setIsLoginModalOpen(true);
    onResetModalClose();
  };

  const onResetModalClose = () => {
    setToken(undefined); //remove token and token id
    setTokenId(undefined); //remove token and token id
    onClose();
    setIsLoading(false);
    setIsSuccess(false);
  };

  useEffect(() => {
    if (isVisible && !canChangePassword && currentUser?.isLoggedIn) {
      //if opened when loggin, submit immediately
      onResetPassword(currentUser?.profile?.email || "");
    }
  }, [isVisible]);

  const onResetPassword = async (email: string) => {
    if (email) {
      setIsLoading(true);
      try {
        await realmApp.emailPasswordAuth.sendResetPasswordEmail({ email });
        setIsSuccess(true);
      } catch (e: any) {
        setErrors({ _: e.error });
      }
      setIsLoading(false);
    }
  };

  const onChangePassword = async (password: string) => {
    if(token && tokenId) {
      setIsLoading(true);
      try {
        await realmApp.emailPasswordAuth.resetPassword({ password, token, tokenId});
        onResetModalClose();
        setIsLoginModalOpen(true);
      }
      catch (e: any) 
      {
        setErrors({ _: e.error });
      }
      setIsLoading(false);
    }
  }

  return (
    <Modal
      isVisible={isVisible}
      className="bg-[#23262B] sm:max-w[880px] sm:w-[880px] sm:scale-100 w-full scale-90 bg-no-repeat bg-cover bg-center py-2 h-fit m-auto flex sm:flex-row flex-col-reverse"
    >
      <div className="sm:w-[65%] w-full">
        {(!canChangePassword && currentUser?.isLoggedIn) || isSuccess ? ( //hack to show reset text for loggedin user
          <ConfirmEmailDialog email={email} action="reset" />
        ) : canChangePassword ? (
          <ChangePassword
            onChangePassword={(password) => {onChangePassword(password)}}
            isLoading={isLoading}
            errors={errors}
          />
        ) : (
          <ResetPassword
            email={email}
            setEmail={setEmail}
            onBack={onBack}
            onResetPassword={onResetPassword}
            isLoading={isLoading}
            errors={errors}
          />
        )}
      </div>
      <div className="sm:w-[35%] w-full bg-no-repeat bg-cover bg-center p-1 relative bg-[url('../images/general/resetModalMobileBg.png')] sm:bg-[url('../images/general/resetModalBg.png')]">
        <div
          className=" hidden sm:flex justify-end cursor-pointer"
          onClick={onResetModalClose}
        >
          <img src={closeIcon} alt="" />
        </div>
        <div className="hidden sm:block m-0 -mr-12">
          <img src={kayiArcher2} alt="" />
        </div>
        <div
          className="sm:hidden w-full h-16 bg-no-repeat bg-cover bg-center px-8 flex items-center justify-between mb-36"
          style={{ backgroundImage: `url(${mobileModalHeader})` }}
        >
          <h1 className="text-white font-normal text-sm leading-6">
            Reset Password
          </h1>
          <span
            className="text-black text-xl cursor-pointer"
            onClick={onResetModalClose}
          >
            <img src={crossIconWhite} alt="" />
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default ResetModal;
