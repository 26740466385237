export enum ToastType {
    DISABLED = 0,
    SUCCESS = 1,
    ERROR = 2
}

export interface ToastState {
    type: ToastType;
    msg: string;
};

export enum Factions {
    TURKISH = 'turkish',
    CRUSADER = 'crusader'
}